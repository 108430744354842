const calculateTransitTime = (productSettings, model) => {
    let result = 0;
    productSettings.forEach((application) => {
        if (application.name === model.settings.application) {
            application.settings.forEach((element) => {
                if (element.material.name === model.settings.material) {
                    result = element.material.delivery_time;
                }
            });
        }
    });

    if (result !== 0) {
        if (result === "") {
            result = 0;
        } else {
            result = parseInt(result);
        }
    }
    if (typeof model.road_transit_days_time !== "undefined") {
        result += parseInt(model.road_transit_days_time);
    }
    model.delivery = result;
    return result;
};

const calculateTransitTimeForOrder = (productSettings, models) => {
    let result = 0;
    let options = [];
    models.forEach((model) => {
        productSettings.forEach((application) => {
            if (application.name === model.settings.application) {
                application.settings.forEach((element) => {
                    if (element.material.name === model.settings.material) {
                        if (typeof model.road_transit_days_time !== "undefined") {
                            options.push(element.material.delivery_time + parseInt(model.road_transit_days_time));
                        } else {
                            options.push(element.material.delivery_time);
                        }
                    }
                });
            }
        });
    });
    options.forEach((option) => {
        if (option !== "" && parseInt(option) > result) {
            result = parseInt(option);
        }
    });

    return result;
};

export { calculateTransitTime, calculateTransitTimeForOrder };
