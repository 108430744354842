import Table from "../Table/Table";
import React, { useEffect, useState } from "react";
import ContactBlock from "../ContentBlock/ContactBlock";
import axios from "axios";
import { useGlobalState } from "../GlobalContext";
import { toast } from "react-toastify";
import { decompressFromBase64 } from "lz-string";

export default function QuotationOverview(props) {
    const { loggedInUser } = useGlobalState();
    const { textData, responsive } = props;
    const [loading, setLoading] = useState(true);
    const [quotationData, setQuotationData] = useState([]);
    const [openPopup, setOpenPopup] = useState(null);

    useEffect(() => {
        // Check in url if a message is given
        let urlParams = new URLSearchParams(window.location.search);
        let message = urlParams.get("message");
        if (typeof message !== "undefined" && message !== null) {
            // Show notification
            message = atob(message);
            toast.error(message);
        }

        getQuotations();
    }, []);

    useEffect(() => {
        loadSelectedQuotation();
    }, [quotationData]);

    const loadSelectedQuotation = () => {
        if (openPopup !== null) return;
        if (typeof quotationData[0]?.orders !== "undefined" && quotationData[0]?.orders.length !== 0) {
            // get id from url param
            let urlParams = new URLSearchParams(window.location.search);
            let id = urlParams.get("id");

            if (id !== null) {
                for (let orderId in quotationData[0]?.orders) {
                    if (quotationData[0]?.orders[orderId].project_number == id) {
                        setOpenPopup({ id: quotationData[0]?.orders[orderId].project_number, type: "quotation" });
                        break;
                    }
                }
            }
        }
    };

    const fetchLoginData = async () => {
        try {
            const response = await axios.get(window.location.origin + "/currentuser");
            return new Promise((resolve, reject) => {
                resolve(response.data);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const getQuotations = () => {
        if (typeof loggedInUser !== "undefined") {
            getQuotationData(loggedInUser);
        } else {
            fetchLoginData().then((user) => {
                getQuotationData(user);
            });
        }
    };

    const getQuotationData = (user) => {
        // Make a GET request for the uploaded models
        const quotationAPI = window.location.origin + "/api/quotations";
        axios
            .get(quotationAPI, {
                params: {
                    user_id: user.id,
                },
            })
            .then((response) => {
                let result = response.data;
                result.section = "savedQuotation";
                setQuotationData([result]);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            {typeof quotationData[0]?.orders !== "undefined" && quotationData[0]?.orders.length !== 0 && (
                <>
                    <h1 style={responsive ? { margin: "0 10px" } : {}}>
                        {textData["account/quotations"]["table"]["title"]}
                    </h1>
                    <Table
                        responsive={responsive}
                        data={quotationData}
                        textData={!!textData && textData["account/quotations"]}
                        textDataLabel={!!textData && textData["sections/label"]}
                        navigateToPageName={props.navigateToPageName}
                        allTextData={!!textData && textData}
                        openPopup={openPopup}
                    />
                </>
            )}
            {loading && (
                <div className="modelviewer-loading centered">
                    <span className="spinner"></span>
                </div>
            )}
            {!!responsive && !!textData && !!textData["content_blocks/contact"] && (
                <ContactBlock responsive={responsive} textData={textData["content_blocks/contact"]} />
            )}
            {(typeof quotationData[0]?.orders === "undefined" || quotationData[0]?.orders.length === 0) && !loading && (
                <h2>{!!textData && textData["account/quotations"]["table"]["no_data"]}</h2>
            )}
        </>
    );
}
