import { useEffect, useState } from "react";

const SearchField = (props) => {
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        const searchField = document.querySelector(".search-input");
        if (isOpened) {
            searchField.classList.add("search-field-opened");
            searchField.focus();
        } else {
            searchField.classList.remove("search-field-opened");
            searchField.value = "";
        }
    }, [isOpened]);

    const filterOrders = (e) => {
        let searchValue = e.target.value.toLowerCase();

        if (searchValue === "") {
            props.setOrderData(props.allOrders);
            return;
        }

        if (typeof props.orderData[0]?.orders !== "undefined") {
            let filteredOrders = props.orderData[0].orders.filter((order) => {
                if (
                    (order.project_number + "").toLocaleLowerCase().includes(searchValue) ||
                    (order.reference !== null && order.reference.toLowerCase().includes(searchValue))
                ) {
                    return order;
                }
            });
            props.setOrderData((prev) => {
                return [{ ...prev[0], orders: filteredOrders }];
            });
        }
    };

    return (
        <div className="search-field">
            <button className="search-field-btn" onClick={() => setIsOpened(!isOpened)}></button>
            <input
                className="search-input"
                type="text"
                placeholder={props.textData["account/order_overview"]["search_button_title"]}
                onChange={filterOrders}
            />
        </div>
    );
};

export default SearchField;
