import React, { useContext, useState } from "react";
export const GlobalContext = React.createContext([{}, () => {}]);
export const GlobalProvider = ({ children }) => {
    const [state, setState] = useState({
        currentPage: null,
        uploadCount: 0,
        data: {},
        activeNavigationMenuItem: "",
        loggedInUser: null,
        productSettings: [],
        mandatoryProductSettings: [],
        generalSettings: [],
        productSettingExplanations: [],
        language: "en",
    });

    const setLanguage = (newLanguage) => {
        setState((prevState) => ({
            ...prevState,
            language: newLanguage,
        }));
    };

    const setGeneralSettings = (newGeneralSettings) => {
        setState((prevState) => ({
            ...prevState,
            generalSettings: newGeneralSettings,
        }));
    };

    const setLoggedInUser = (user) => {
        setState((prevState) => ({
            ...prevState,
            loggedInUser: user,
        }));
    };

    const setProductSettings = (newProductSettings) => {
        setState((prevState) => ({
            ...prevState,
            productSettings: newProductSettings,
        }));
    };

    const setMandatoryProductSettings = (newMandatoryProductOptions) => {
        setState((prevState) => ({
            ...prevState,
            mandatoryProductSettings: newMandatoryProductOptions,
        }));
    };

    const setProductSettingExplanations = (newProductSettingExplanations) => {
        setState((prevState) => ({
            ...prevState,
            productSettingExplanations: newProductSettingExplanations,
        }));
    };

    const incrementUploadCount = () => {
        setState((prevState) => {
            if (typeof prevState.uploadCount === "undefined") {
                return {
                    ...prevState,
                    uploadCount: 1,
                };
            } else {
                return {
                    ...prevState,
                    uploadCount: prevState.uploadCount + 1,
                };
            }
        });
    };

    const setData = (newData) => {
        setState((prevState) => ({
            ...prevState,
            data: newData,
        }));
    };

    const setCurrentPage = (newCurrentPage) => {
        setState((prevState) => ({
            ...prevState,
            currentPage: newCurrentPage,
        }));
    };

    const setActiveNavigationMenuItem = (newActiveNavigationMenuItem) => {
        setState((prevState) => ({
            ...prevState,
            activeNavigationMenuItem: newActiveNavigationMenuItem,
        }));
    };

    return (
        <GlobalContext.Provider
            value={[
                state,
                {
                    setGeneralSettings,
                    setMandatoryProductSettings,
                    setProductSettings,
                    setLoggedInUser,
                    setCurrentPage,
                    incrementUploadCount,
                    setData,
                    setActiveNavigationMenuItem,
                    setProductSettingExplanations,
                    setLanguage,
                },
            ]}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobalState = () => {
    const [state, actions] = useContext(GlobalContext);
    return {
        language: state.language,
        setLanguage: actions.setLanguage,
        setProductSettingExplanations: actions.setProductSettingExplanations,
        productSettingExplanations: state.productSettingExplanations,
        generalSettings: state.generalSettings,
        setGeneralSettings: actions.setGeneralSettings,
        mandatoryProductSettings: state.mandatoryProductSettings,
        setMandatoryProductSettings: actions.setMandatoryProductSettings,
        productSettings: state.productSettings,
        setProductSettings: actions.setProductSettings,
        currentPage: state.currentPage,
        setCurrentPage: actions.setCurrentPage,
        uploadCount: state.uploadCount,
        loggedInUser: state.loggedInUser,
        setLoggedInUser: actions.setLoggedInUser,
        incrementUploadCount: actions.incrementUploadCount,
        data: state.data,
        setData: actions.setData,
        activeNavigationMenuItem: state.activeNavigationMenuItem,
        setActiveNavigationMenuItem: actions.setActiveNavigationMenuItem,
    };
};
