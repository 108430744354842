import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import RetrieveProductSettings from "../ProductSettings/RetrieveProductSettings";
import { useGlobalState } from "../GlobalContext";

export default function TableFooter(props) {
    const { className, buttons, liveChat, popupData, loginPopupData, data } = props.data;
    const [mandatoryProductSettings, setMandatoryProductSettings] = useState([]);
    const { generalSettings, loggedInUser } = useGlobalState();

    const getWhatsapp = () => {
        if (
            generalSettings["whatsapp"] !== null &&
            typeof generalSettings["whatsapp"] !== "undefined" &&
            generalSettings["whatsapp"] !== ""
        ) {
            return generalSettings["whatsapp"];
        }
    };

    useEffect(() => {
        RetrieveProductSettings({ setMandatoryProductSettings });
    }, []);

    //@todo: make this dynamic, this is now fixed for testing purposes
    const openWhatsApp = () => {
        window.open("https://wa.me/" + getWhatsapp(), "_blank");
    };

    const getTotalPrice = () => {
        if (props.totalPriceLoading) return "€ ...";

        let total = 0;
        data.forEach((element) => {
            if (typeof element.tempId === "undefined") {
                let modelPrice = 0;
                if (
                    (loggedInUser.company === null ||
                        (loggedInUser.company !== null && loggedInUser.company.allow_staffel_discount != false)) &&
                    !/[a-z]/i.test(parseFloat(element.price))
                ) {
                    if (typeof element.discount_price !== "undefined" && element.discount_price !== null) {
                        modelPrice = parseFloat(element.discount_price);
                    } else {
                        modelPrice =
                            parseFloat(element.price) * element.amount * parseFloat(element.discount_multiplier);
                    }
                } else if (
                    loggedInUser.company !== null &&
                    loggedInUser.company.allow_staffel_discount == false &&
                    !/[a-z]/i.test(parseFloat(element.price))
                ) {
                    modelPrice = parseFloat(element.price) * element.amount;
                } else if (
                    typeof element.discount_price !== "undefined" &&
                    element.discount_price !== null &&
                    !/[a-z]/i.test(parseFloat(element.price))
                ) {
                    modelPrice = parseFloat(element.discount_price);
                } else if (!/[a-z]/i.test(element.price)) {
                    modelPrice = parseFloat(element.price) * element.amount;
                } else {
                    modelPrice = 0;
                }

                total += parseFloat(modelPrice);
            }
        });

        if (total < 0) {
            total = 0;
        }

        if (typeof props.productsData !== "undefined" && props.productsData.length > 0) {
            let productsTotal = 0;
            props.productsData.forEach((product) => {
                productsTotal += parseFloat(product.price) * product.quantity;
            });
            total += productsTotal;
        }

        if (isNaN(total)) {
            return "€ ...";
        } else {
            return "€ " + total.toFixed(2);
        }
    };

    const getExtraCosts = (models) => {
        let result = 0;
        if (models.length === 0) return 0;
        models.forEach((model) => {
            if (typeof mandatoryProductSettings !== "undefined" && typeof model.tempId === "undefined") {
                mandatoryProductSettings.forEach((setting) => {
                    if (model.settings.application === setting.application) {
                        if (
                            setting.application === "Medical" &&
                            (model.settings.sterile === "true" || model.settings.sterile === true)
                        ) {
                            result = setting.mandatory_options[0].price;
                        } else if (setting.application !== "Medical") {
                            result = setting.mandatory_options[0].price;
                        }
                    }
                });
            } else {
                return 0;
            }
        });
        return result;
    };

    return (
        <>
            {!props.responsive && mandatoryProductSettings !== null && (
                <div className={className}>
                    <div className="buttons">
                        <Button
                            navigateToPageName={props.navigateToPageName}
                            data={buttons}
                            popupData={popupData}
                            loginData={loginPopupData}
                            saveQuotation={props.saveQuotation}
                            saveQuotationAndRedirectCheckout={props.saveQuotationAndRedirectCheckout}
                            showButtons={
                                (typeof props.data.data !== "undefined" && props.data.data.length > 0) ||
                                (typeof props.productsData !== "undefined" && props.productsData.length > 0)
                            }
                            onModelChange={props.onModelChange}
                            disableCheckoutButton={props.disableCheckoutButton}
                            setSaveFooterSettings={props.setSaveFooterSettings}
                        />
                    </div>
                    <div className="total-price">
                        <div>{props.textData["footer"]["total_amount"]}</div>
                        <div>{getTotalPrice()}</div>
                    </div>
                    <div className="livechat">
                        <span
                            className={liveChat.icon}
                            onClick={() => {
                                openWhatsApp();
                            }}
                        >
                            {liveChat.text}
                        </span>
                    </div>
                </div>
            )}
            {!!props.responsive && mandatoryProductSettings !== null && (
                <div className={props.responsive + className}>
                    <div className="total-price">
                        <div>
                            {props.textData["footer"]["total_amount"]} {getTotalPrice()}
                        </div>
                    </div>
                    <div className="buttons">
                        <Button
                            navigateToPageName={props.navigateToPageName}
                            data={buttons}
                            saveQuotationAndRedirectCheckout={props.saveQuotationAndRedirectCheckout}
                            popupData={popupData}
                            loginData={loginPopupData}
                            showButtons={
                                props.data.data.length > 0 ||
                                (typeof props.productsData !== "undefined" && props.productsData.length > 0)
                            }
                            onModelChange={props.onModelChange}
                            disableCheckoutButton={props.disableCheckoutButton}
                            textData={props.textData}
                        />
                        {/* Link to liveChat component */}
                        <div
                            className="livechat-container"
                            onClick={() => {
                                openWhatsApp();
                            }}
                        >
                            <FontAwesomeIcon className={liveChat.icon} icon={faWhatsapp} size="3x" />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
