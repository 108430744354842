export const getSettingsId = (selectedSettings, productSettings) => {
    if (
        (productSettings !== undefined && Object.keys(productSettings).length === 0) ||
        typeof selectedSettings === "undefined" ||
        selectedSettings === null
    ) {
        return "";
    }
    let result = "";
    productSettings.forEach((application) => {
        if (application.name === selectedSettings.application) {
            result = application.settings.filter((setting) => {
                let settingClinicalUse = selectedSettings.clinicalUse;
                if (settingClinicalUse === "No") {
                    settingClinicalUse = false;
                } else if (settingClinicalUse === "Yes") {
                    settingClinicalUse = true;
                }

                let settingSterile = selectedSettings.sterile;
                if (settingSterile === "No") {
                    settingSterile = false;
                } else if (settingSterile === "Yes") {
                    settingSterile = true;
                }

                let settingCustom = selectedSettings.custom;
                if (settingCustom === "No") {
                    settingCustom = false;
                } else if (settingCustom === "Yes") {
                    settingCustom = true;
                }

                let settingIntendedUse = selectedSettings.intendedUse;
                if (settingIntendedUse === "No") {
                    settingIntendedUse = false;
                } else if (settingIntendedUse === "Yes") {
                    settingIntendedUse = true;
                }

                const coatingCheck =
                    setting.coating === selectedSettings.coating ||
                    selectedSettings.coating == null ||
                    selectedSettings.coating === "";
                const materialCheck =
                    setting.material.name === selectedSettings.material ||
                    selectedSettings.material == null ||
                    selectedSettings.material.name === null ||
                    selectedSettings.material.name === "";
                const orientationCheck =
                    setting.orientation === selectedSettings.orientation ||
                    selectedSettings.orientation == null ||
                    selectedSettings.orientation === "";
                const polishCheck =
                    setting.polish === selectedSettings.polish ||
                    selectedSettings.polish == null ||
                    selectedSettings.polish === "";
                const colorCheck =
                    setting.color.name === selectedSettings.color ||
                    selectedSettings.color == null ||
                    selectedSettings.color.name === null ||
                    selectedSettings.color === "";
                const sterileCheck =
                    setting.sterile === settingSterile ||
                    selectedSettings.sterile == null ||
                    selectedSettings.sterile === "";
                const intendedUseCheck =
                    setting.intendedUse === settingIntendedUse ||
                    selectedSettings.intendedUse == null ||
                    selectedSettings.intendedUse === "";
                const clinicalUseCheck =
                    setting.clinicalUse === settingClinicalUse ||
                    selectedSettings.clinicalUse == null ||
                    selectedSettings.clinicalUse === "";
                const customCheck =
                    setting.custom === settingCustom ||
                    selectedSettings.custom == null ||
                    selectedSettings.custom === "";

                // log all checks
                // console.log({
                //     "coatingCheck": coatingCheck,
                //     "materialCheck": materialCheck,
                //     "orientationCheck": orientationCheck,
                //     "polishCheck": polishCheck,
                //     "colorCheck": colorCheck,
                //     "sterileCheck": sterileCheck,
                //     "intendedUseCheck": intendedUseCheck,
                //     "clinicalUseCheck": clinicalUseCheck,
                //     "customCheck": customCheck
                // },
                // {
                //     "setting": setting,
                //     "selectedSettings": selectedSettings
                // })

                return (
                    coatingCheck &&
                    materialCheck &&
                    orientationCheck &&
                    polishCheck &&
                    colorCheck &&
                    sterileCheck &&
                    intendedUseCheck &&
                    clinicalUseCheck &&
                    customCheck
                );
            });
        }
    });

    if (result && result !== "" && result.length > 0) {
        return result[0].id;
    }
    return "";
};
