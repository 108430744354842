import Table from "../Table/Table";
import ContactBlock from "../ContentBlock/ContactBlock";
import axios from "axios";
import SearchField from "./SearchField";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../GlobalContext";

export default function OrderOverview(props) {
    const { textData, responsive } = props;
    const { loggedInUser } = useGlobalState();
    let [orderData, setOrderData] = useState([]);
    let [allOrders, setAllOrders] = useState([]);
    const [openPopup, setOpenPopup] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getQuotations();
    }, []);

    useEffect(() => {
        loadSelectedOrder();
    }, [orderData]);

    const loadSelectedOrder = () => {
        if (openPopup !== null) return;
        if (typeof orderData[0]?.orders !== "undefined" && orderData[0]?.orders.length !== 0) {
            // get id from url param
            let urlParams = new URLSearchParams(window.location.search);
            let id = urlParams.get("id");

            if (id !== null) {
                for (let orderId in orderData[0]?.orders) {
                    if (orderData[0]?.orders[orderId].project_number == id) {
                        setOpenPopup({ id: orderData[0]?.orders[orderId].project_number, type: "order" });
                        break;
                    }
                }
            }
        }
    };

    const getQuotations = () => {
        // Make a GET request for the uploaded models
        const quotationAPI = window.location.origin + "/api/orders";
        axios
            .get(quotationAPI, {
                params: {
                    user_id: loggedInUser.user.id,
                },
            })
            .then((response) => {
                let result = response.data;
                result.section = "orders";
                setOrderData([result]);
                setAllOrders([result]);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    return (
        <>
            {typeof orderData[0]?.orders !== "undefined" && orderData[0]?.orders.length !== 0 && (
                <>
                    <div className="orders-overview-title">
                        <SearchField
                            textData={textData}
                            orderData={orderData}
                            setOrderData={setOrderData}
                            allOrders={allOrders}
                        />
                    </div>
                    <Table
                        responsive={responsive}
                        data={orderData}
                        textData={!!textData && textData["account/order_overview"]}
                        textDataLabel={!!textData && textData["sections/label"]}
                        navigateToPageName={props.navigateToPageName}
                        allTextData={!!textData && textData}
                        openPopup={openPopup}
                    />
                </>
            )}
            {(typeof orderData[0]?.orders === "undefined" || orderData[0]?.orders.length === 0) && !loading && (
                <h2>{!!textData && textData["account/order_overview"]["table"]["no_data"]}</h2>
            )}
            {loading && (
                <div className="modelviewer-loading centered">
                    <span className="spinner"></span>
                </div>
            )}
            {!!responsive && !!textData && !!textData["content_blocks/contact"] && (
                <ContactBlock responsive={responsive} textData={textData["content_blocks/contact"]} />
            )}
        </>
    );
}
