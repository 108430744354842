import { useState, useEffect, useRef, Fragment } from "react";
import ProductSettings from "./ProductSettings/ProductSettings";
import Table from "./Table/Table";
import NavigationMenu from "./NavigationMenu";
import Upload from "./Upload/Upload";
import QuotationBlock from "./ContentBlock/QuotationBlock";
import axios from "axios";
import Sidebar from "./Sidebar";
import OrderOverview from "./Account/OrderOverview";
import QuotationOverview from "./Account/QuotationOverview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { useGlobalState } from "./GlobalContext";
import { useMediaQuery } from "react-responsive";
import ProductLibraryOverview from "./Account/ProductLibraryOverview";
import Settings from "./Account/Settings";
import Details from "./Account/Details";
import { useLocation } from "react-router-dom";
import LoginPopup from "./Popups/LoginPopup";
import ColorUtil from "./ModelViewer/ColorUtil";
import { updateModelColor } from "./ModelViewer/ColorUpdater";
import RetrieveProductSettings from "./ProductSettings/RetrieveProductSettings";
import { getSettingsId } from "./ProductSettings/Helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export default function EditQuotation(props) {
    // props from react-router-dom in app.jsx
    const [modelsData, setModelsData] = useState();
    const getColorInRgb = ColorUtil(props);
    const { uploadCount } = useGlobalState();
    const [textData, setTextData] = useState(null);
    const { loggedInUser } = useGlobalState();
    const [updatingMultipleModels, setUpdatingMultipleModels] = useState(false);
    const { activeNavigationMenuItem, setActiveNavigationMenuItem } = useGlobalState();
    const lastUploadedModels = useRef([]);
    // const [activeMenuItem, setActiveMenuItem] = useState(selectedMenuItem);
    const [hasModelsSelected, setHasModelsSelected] = useState(false);
    const [tempModelViewers, setTempModelViewers] = useState([]);
    const { productSettings, mandatoryProductSettings, setProductSettings, setMandatoryProductSettings, currentPage } =
        useGlobalState();
    const [loginModal, setLoginModal] = useState(false);
    const initialLoad = useRef(false);
    const loadedInitModelColors = useRef(false);
    const updatingPrices = useRef(false);
    const initLoadPrices = useRef(false);
    const [originalQuotationModels, setOriginalQuotationModels] = useState(null);
    const responsiveClass = "rsp-";
    const { state } = useLocation();
    const [quotationId, setQuotationId] = useState(null);
    const [quotationApiData, setQuotationApiData] = useState(null);
    const [quotationFormData, setQuotationFormData] = useState(null);
    const updateNextIteration = useRef(false);
    const initQuotationLoaded = useRef(false);
    const [modelsUpdaterQueue, setModelsUpdaterQueue] = useState([]);
    const [amountOfModelsBeingColored, setAmountOfModelsBeingColored] = useState(0);
    const [disableProductSettings, setDisableProductSettings] = useState(false);

    // MediaQuery variables
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1090px)" });

    useEffect(() => {
        updatingPrices.current = disableProductSettings;
    }, [disableProductSettings]);

    useEffect(() => {
        if (updatingMultipleModels) {
            updatePricesForAllModels();
        }
    }, [updatingMultipleModels]);

    useEffect(() => {
        RetrieveProductSettings({ setMandatoryProductSettings, setProductSettings });
        setTextData(props.textData);
        // set current page if not set correctly
        if (currentPage !== "account_edit_quotation") {
            props.initLoadPage();
        }
    }, []);

    useEffect(() => {
        if (modelsUpdaterQueue.length > 0 && amountOfModelsBeingColored < 1) {
            setAmountOfModelsBeingColored((prev) => {
                return prev + 1;
            });
            let model = modelsUpdaterQueue[0];
            let resultingPromise = null;
            if (
                typeof model.settings !== "undefined" &&
                typeof model.settings.color !== "undefined" &&
                model.settings.color !== null
            ) {
                if (model.color !== null && typeof model.color !== "undefined") {
                    let promise = updateModelColor(model, modelsData, model.color, null, handleScreenshot);
                    resultingPromise = promise;
                } else {
                    resultingPromise = getColorInRgb(model.settings.color).then((rgb) => {
                        return updateModelColor(model, modelsData, rgb, null, handleScreenshot);
                    });
                }
            } else {
                // Dont need to update color
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((stateModel) => {
                        if (stateModel.id === model.id) {
                            stateModel.loading = false;
                        }
                        return stateModel;
                    });
                    let result = [{ ...prevState[0], data: newData }];
                    // set localStorage
                    localStorage.setItem("modelsData", JSON.stringify(result));
                    return result;
                });

                setTimeout(() => {
                    // remove model from queue
                    let newQueue = modelsUpdaterQueue;
                    newQueue.shift();
                    setModelsUpdaterQueue(newQueue);
                    setAmountOfModelsBeingColored((prev) => {
                        return 0;
                    });
                }, 700);
            }

            resultingPromise
                .then((result) => {
                    setTimeout(() => {
                        setTempModelViewers([result]);
                        // remove model from queue
                        let newQueue = modelsUpdaterQueue;
                        newQueue.shift();
                        setModelsUpdaterQueue(newQueue);
                        setAmountOfModelsBeingColored((prev) => {
                            return 0;
                        });
                    }, 700);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [modelsUpdaterQueue, amountOfModelsBeingColored]);

    useEffect(() => {
        let orderId = getOrderId();
        setQuotationId(orderId);
        if (orderId) {
            axios
                .get(window.location.origin + `/api/quotation?quotation_id=${orderId}&user_id=${loggedInUser.user.id}`)
                .then((response) => {
                    if (response.data.order.admin_edited === true) {
                        props.navigateToPageName("home");
                        toast.error(props.textData["sections/quotation"]["not_editable"]);
                    }

                    setQuotationApiData(response.data);
                    //setQuotationFormValues(response.data.order.user);
                    //setModels(response.data["order"]);

                    let models = response.data.order.models;
                    models = models.map((model) => {
                        if (typeof model.amount === "undefined" || model.amount === null) {
                            model.amount = model.pivot.amount;
                        }
                        return model;
                    });

                    localStorage.setItem(
                        "modelsDataQuotation",
                        JSON.stringify([{ section: "quotation", data: models }])
                    );
                    setModelsData((prev) => {
                        return [{ section: "quotation", data: models }];
                    });
                    setOriginalQuotationModels((prev) => {
                        return [{ section: "quotation", data: models }];
                    });
                    initQuotationLoaded.current = true;
                    if (!loadedInitModelColors.current) {
                        loadModelColors(models, null, true);
                        loadedInitModelColors.current = true;
                    }
                    models.forEach((model) => {
                        updateShippingCosts(model);
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, []);

    function getOrderId() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("id");
    }

    useEffect(() => {
        if (tempModelViewers.length > 0) {
            setTimeout(() => {
                setTempModelViewers([]);
            }, 1000);
        }
    }, [tempModelViewers]);

    useEffect(() => {
        if (!initLoadPrices.current && typeof modelsData !== "undefined" && typeof productSettings !== "undefined") {
            updatePrices(false);
            initLoadPrices.current = true;
        }
    }, [productSettings, modelsData]);

    useEffect(() => {
        if (initQuotationLoaded.current === true) {
            getModelDataFromApi();
        }
    }, [uploadCount]);

    function getModelDataFromApi() {
        // Make a GET request for the uploaded models
        const modelDataAPI = window.location.origin + "/models";
        axios
            .get(modelDataAPI)
            .then((response) => {
                mapModelsToData(response.data)
                    .then((data) => {
                        if (data !== null) {
                            setModelsData([
                                {
                                    section: "quotation",
                                    data: data,
                                },
                            ]);
                        }
                        if (!loadedInitModelColors.current) {
                            loadModelColors(data, null, true);
                            loadedInitModelColors.current = true;
                        }
                    })
                    .catch((error) => {
                        console.error("Error retrieving mapModelsToData:", error);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const loadModelColors = async (models, orientation = null, initial = false) => {
        // Set selected models to loading state
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((stateModel) => {
                models.forEach((model) => {
                    if (stateModel.id === model.id && (stateModel.checked || initial)) {
                        stateModel.loading = true;
                    }
                });
                return stateModel;
            });

            let result = [{ ...prevState[0], data: newData }];
            return result;
        });

        setModelsUpdaterQueue((prevState) => {
            return models;
        });
    };

    const mapModelsToData = async (inputModels) => {
        // Check if data in local storage
        let localModelsData = JSON.parse(localStorage.getItem("modelsDataQuotation")) || [];
        localModelsData = typeof localModelsData[0]?.data !== "undefined" ? localModelsData[0].data : [];
        let defaultSettings = {
            application: "Retail & Industrial",
            material: "Oceanz PA12",
            color: "No colouring",
            polish: "No",
            coating: "No",
            orientation: "unlocked",
            sterile: null,
            intendedUse: null,
            clinicalUse: null,
            custom: null,
            case: null,
            practitioner: null,
        };

        // Check if current logged in user default settings are set
        if (loggedInUser?.user !== null && typeof loggedInUser?.user !== "undefined") {
            if (
                loggedInUser?.user?.default_settings !== null &&
                typeof loggedInUser?.user?.default_settings !== "undefined"
            ) {
                defaultSettings = {
                    application: loggedInUser.user.default_settings.application,
                    material: loggedInUser.user.default_settings.material,
                    color: loggedInUser.user.default_settings.color,
                    polish: loggedInUser.user.default_settings.polish,
                    coating: loggedInUser.user.default_settings.coating,
                    orientation: loggedInUser.user.default_settings.orientation,
                    sterile:
                        loggedInUser.user.default_settings.sterile === "No"
                            ? null
                            : loggedInUser.user.default_settings.sterile,
                    intendedUse: loggedInUser.user.default_settings.intendedUse,
                    clinicalUse: loggedInUser.user.default_settings.clinicalUse,
                    custom: loggedInUser.user.default_settings.custom,
                    case:
                        loggedInUser.user.default_settings.custom !== undefined &&
                        (loggedInUser.user.default_settings.custom === "Yes" ||
                            loggedInUser.user.default_settings.custom)
                            ? "-"
                            : null,
                    practitioner:
                        loggedInUser.user.default_settings.custom !== undefined &&
                        (loggedInUser.user.default_settings.custom === "Yes" ||
                            loggedInUser.user.default_settings.custom)
                            ? "-"
                            : null,
                };
            }
        }

        // If a new model is uploaded, get the new model data from API
        if (lastUploadedModels.current.length > 0) {
            const modelDataAPI = window.location.origin + "/models";
            await axios
                .get(modelDataAPI)
                .then((response) => {
                    let newUploadedModels = []; // Array to store the new uploaded models
                    let apiModels = response.data.models;
                    apiModels.map((model) => {
                        let result = {
                            id: model.id,
                            path: model.path,
                            file: model.file,
                            image: model.preview_image,
                            settings: typeof model.settings !== "undefined" ? model.settings : defaultSettings,
                            cached_model:
                                typeof model.cached_model !== "undefined" ? JSON.parse(model.cached_model) : null,
                            delivery: "...",
                            amount: 1,
                            price: "Op aanvraag",
                        };

                        lastUploadedModels.current.forEach((mdl) => {
                            if (model.id === mdl.id) {
                                // Set default settings
                                result.settings = defaultSettings;

                                setModelsData((prev) => {
                                    // Update model data
                                    let newState = prev[0].data.map((stateModel) => {
                                        if (stateModel.tempId === mdl.tempId) {
                                            stateModel = result;
                                        }
                                        return stateModel;
                                    });

                                    localStorage.setItem(
                                        "modelsDataQuotation",
                                        JSON.stringify([{ ...prev[0], data: newState }])
                                    );
                                    return [{ ...prev[0], data: newState }];
                                });

                                newUploadedModels.push(result);
                                updatePrice(result);
                                updateShippingCosts(result);
                            }
                        });
                        return result;
                    });
                    lastUploadedModels.current = [];
                    return newUploadedModels;
                })
                .catch((error) => {
                    console.error(error);
                });
            // merge modelsData and result
            return null;
        }

        if (!!inputModels["models"]) {
            let models = inputModels["models"].map((model) => {
                let result = {
                    id: model.id,
                    path: model.path,
                    file: model.file,
                    image: model.preview_image,
                    settings: model.settings !== "undefined" ? model.settings : defaultSettings,
                    cached_model: typeof model.cached_model !== "undefined" ? JSON.parse(model.cached_model) : null,
                    delivery: "...",
                    amount: 1,
                    price: "Op aanvraag",
                };

                if (localModelsData.length > 0) {
                    localModelsData.forEach((mdl) => {
                        if (mdl.id === model.id) {
                            if (typeof mdl.settings !== "undefined") {
                                result.settings = mdl.settings;
                                result.image = mdl.image;
                            } else {
                                result.settings = defaultSettings;
                            }
                        }
                    });
                }
                return result;
            });

            try {
                const localStorageData = JSON.parse(window.localStorage.getItem("modelsDataQuotation"));
                if (
                    localStorageData !== null &&
                    typeof localStorageData !== "undefined" &&
                    localStorageData.length > 0
                ) {
                    models = localStorageData[0].data.map((model) => {
                        let result = model;
                        models.forEach((mdl) => {
                            if (mdl.id === model.id) {
                                result = mdl;
                                result.image = mdl.image;
                            }
                        });
                        return result;
                    });
                } else {
                    models = [];
                }
            } catch (err) {
                console.error(err, "resetting modelsData in local storage");
                // Reset models
                models = [];
                window.localStorage.setItem("modelsDataQuotation", "[]");
            }

            models.forEach((model) => {
                updateShippingCosts(model);
            });

            return new Promise((resolve, reject) => {
                resolve(models);
            });
        }
    };

    const savedQuotationData = [
        {
            section: "savedQuotation",
            data: [
                {
                    creation_date: "20-02-2023",
                    reference: "Tandarts nijkerk 19",
                    status: "Offerte",
                    price: "59,41",
                },
            ],
        },
    ];

    const orderData = [
        {
            section: "orders",
            orders: [
                {
                    projectnr: 2023001,
                    orderDate: "03-02-2023",
                    reference: "Tandarts nijkerk 19",
                    status: "In productie",
                    price: "59,41",
                },
                {
                    projectnr: 2024001,
                    orderDate: "05-02-2023",
                    reference: "Knieprothese",
                    status: "Verzonden",
                    price: "59,41",
                },
                {
                    projectnr: 2025001,
                    orderDate: "12-06-2023",
                    reference: "Schaakstukken",
                    status: "Afgerond",
                    price: "59,41",
                },
            ],
        },
    ];

    const productLibraryData = [
        {
            section: "library",
            data: [
                {
                    filename: "Bla.3mf",
                    settings: ["Oceanz PA14", "Brown dye", "Getrommeld", "SLS", "Polished", "Orientation"],
                    quantity: 1,
                    price: "43,99",
                },
                {
                    filename: "Test.3mf",
                    settings: ["Oceanz PA14", "Black dye", "Getrommeld", "SLS", "Polished", "Orientation"],
                    quantity: 1,
                    price: "33,99",
                },
            ],
        },
    ];

    const [orders, setOrders] = useState(orderData);
    const [products, setProducts] = useState(productLibraryData);
    const [savedQuotations, setSavedQuotations] = useState(savedQuotationData);

    // This useEffect is used to set the active menu item in the primary menu based on the current url. This is necessary if user is using
    // forward and back buttons in the browser.
    useEffect(() => {
        setActiveNavigationMenuItem(props.selectedMenuItem);
    }, [props.selectedMenuItem]);

    // This useEffect is used to set the modelsData state when the getModels state is updated.
    // useEffect(() => {
    //     if (!!getModels) {
    //         setModelsData(quotationData);
    //     }
    // }, [getModels, uploadCount]);

    // This useEffect is used to set a scrollable page for the quotation section.
    useEffect(() => {
        const appContainer = document.getElementById("app");
        if (activeNavigationMenuItem === "navigate-quotation" && isTabletOrMobile) {
            appContainer.style.overflowY = "scroll";
            appContainer.style.height = "calc(95vh - 100px)";
        } else {
            appContainer.style.overflowY = "auto";
            appContainer.style.height = "100%";
        }
    }, [activeNavigationMenuItem, window.innerWidth]);

    // Callback function to receive updated settings from the product settings component.
    function updateModelSettings(config) {
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.checked === true) {
                    return { ...model, settings: Object.assign({}, config) };
                } else {
                    return model;
                }
            });
            let result = [{ ...prevState[0], data: newData }];
            return result;
        });
    }

    // Update the left part of screen with either the product settings or the upload component.
    useEffect(() => {
        if (updateNextIteration.current === true) {
            updatePrices();
            updateNextIteration.current = false;
        }

        let result = false;
        if (modelsData !== undefined && modelsData.length > 0) {
            if (modelsData[0].data !== undefined && modelsData[0].data.length > 0) {
                modelsData[0].data.forEach((model) => {
                    if (model.checked === true) {
                        result = true;
                    }
                });
            }
        }
        setHasModelsSelected(result);
    }, [modelsData]);

    // Handle the screenshot from the modelviewer and update the model in the database.
    function handleScreenshot(imageData, modelId, color) {
        // Set the model data locally
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.id === modelId) {
                    model.image = imageData;
                    model.color = color;
                    model.loading = false;
                }
                return model;
            });
            let result = [{ ...prevState[0], data: newData }];
            return result;
        });
    }

    function updateSelectedModelsColor(color) {
        // Get all checked models
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                // Update localstate
                setModelsData((prevState) => {
                    let models = [];
                    const newData = prevState[0].data.map((model) => {
                        if (model.checked) {
                            model.color = color;
                            model.loading = true; // set loading to true in order to show spinning loader
                            models.push(model);
                        }
                        return { ...model };
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    loadModelColors(models);
                    window.localStorage.setItem("modelsDataQuotation", JSON.stringify(result)); // set local storage
                    return result;
                });
            }
        }
    }

    const updateModelDeliveryTime = (value) => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                let result = [];
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((model) => {
                        if (model.checked === true) {
                            return { ...model, delivery: value };
                        }
                        return { ...model };
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    window.localStorage.setItem("modelsDataQuotation", JSON.stringify(result)); // set local storage
                    return result;
                });
            }
        }
    };

    const updateModelOrientation = (value) => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                loadModelColors(modelsData[0].data, value);
            }
        }
    };

    function saveProductSettings(chosenConfig) {
        // Unselect all models.
        document.getElementsByClassName("checkbox--select-all")[0].checked = false;
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.checked === true) {
                    return { ...model, checked: false, settings: chosenConfig };
                }
                return { ...model, checked: false };
            });
            const result = [{ ...prevState[0], data: newData }];
            window.localStorage.setItem("modelsDataQuotation", JSON.stringify(result)); // set local storage

            return result;
        });
    }

    function afterUpload(realId, tempId) {
        lastUploadedModels.current.push({ id: realId, tempId: tempId });
    }

    // Update a single model price
    const updatePrice = (element) => {
        // Set model state to loading
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.id === element.id) {
                    model.loadingPrice = true;
                }
                return model;
            });
            return [{ ...prevState[0], data: newData }];
        });

        let params = "";
        let found = false;
        for (let setting in element.settings) {
            if (setting === "intendedUse") {
                found = true;
            }

            if (
                element.settings[setting] !== "" &&
                element.settings[setting] !== null &&
                typeof element.settings[setting] !== "undefined"
            ) {
                params +=
                    "&" + encodeURIComponent(`${setting}`) + "=" + encodeURIComponent(`${element.settings[setting]}`);
            } else {
                params += "&" + encodeURIComponent(`${setting}`) + "=null";
            }
        }
        if (!found) {
            params += "&intendedUse=null";
        }
        axios
            .get(window.location.origin + `/api/product-price?model_id=${element.id}${params}&amount=${element.amount}`)
            .then((response) => {
                // Cant get price, show default
                if (typeof response.data.message === "undefined" && typeof response.data.data.length === "undefined") {
                    element.price = "Op aanvraag";
                } else {
                    // Loop through the returned models and update the price
                    response.data.data.forEach((item) => {
                        if (item.model_id === element.id) {
                            element.price = item.unit_price;
                            element.discount_multiplier = item.discount_multiplier;
                        }
                    });
                }
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((model) => {
                        if (model.id === element.id) {
                            model.price = element.price;
                            model.loadingPrice = false;
                            model.discount_multiplier = element.discount_multiplier;
                        }
                        return model;
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    window.localStorage.setItem("modelsDataQuotation", JSON.stringify(result)); // set local storage
                    return result;
                });
            })
            .catch((error) => {
                if (window.location.pathname === props.getPageUrlByName("account_edit_quotation").path) {
                    let result = null;
                    let found = false;
                    const localStorageData = JSON.parse(window.localStorage.getItem("modelsDataQuotation"));
                    localStorageData[0].data.forEach((m) => {
                        if (element.id === m.id && element.path === m.path) {
                            if (
                                typeof element.pivot !== "undefined" &&
                                typeof element.pivot.price !== "undefined" &&
                                element.pivot.price !== null
                            ) {
                                found = true;
                                let equalSettings = true;
                                for (let setting in element.settings) {
                                    if (element.settings[setting] !== m.settings[setting]) {
                                        equalSettings = false;
                                    }
                                }

                                if (equalSettings && element.price !== "Op aanvraag") {
                                    setModelsData((prevState) => {
                                        const newData = prevState[0].data.map((model) => {
                                            if (model.id === element.id) {
                                                model.price = parseFloat(element.pivot.price);

                                                model.loadingPrice = false;
                                            }
                                            return model;
                                        });
                                        result = [{ ...prevState[0], data: newData }];
                                        window.localStorage.setItem("modelsDataQuotation", JSON.stringify(result)); // set local storage
                                        return result;
                                    });
                                } else {
                                    setModelsData((prevState) => {
                                        const newData = prevState[0].data.map((model) => {
                                            if (model.id === element.id) {
                                                model.loadingPrice = false;
                                                model.price = "Op aanvraag";
                                            }
                                            return model;
                                        });
                                        const result = [{ ...prevState[0], data: newData }];
                                        window.localStorage.setItem("modelsDataQuotation", JSON.stringify(result)); // set local storage
                                        return result;
                                    });
                                }
                            }
                        }
                    });
                    if (!found) {
                        element.loadingPrice = false;
                        setModelsData((prevState) => {
                            const newData = prevState[0].data.map((model) => {
                                if (model.id === element.id) {
                                    model.loadingPrice = false;
                                    model.price = "Op aanvraag";
                                }
                                return model;
                            });
                            const result = [{ ...prevState[0], data: newData }];
                            window.localStorage.setItem("modelsDataQuotation", JSON.stringify(result)); // set local storage
                            return result;
                        });
                    }
                    return element;
                }

                console.log(error);
                element.loadingPrice = false;
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((model) => {
                        if (model.id === element.id) {
                            model.loadingPrice = false;
                            model.price = "Op aanvraag";
                        }
                        return model;
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    window.localStorage.setItem("modelsDataQuotation", JSON.stringify(result)); // set local storage
                    return result;
                });
            })
            .finally(() => {
                updatingPrices.current = false;
                setDisableProductSettings(false);
            });
        return element;
    };

    const updatePricesOnNextIteration = () => {
        updateNextIteration.current = true;
    };

    const updatePricesForAllModels = (models = null) => {
        // Set model state to loading
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.checked === true) {
                    model.loadingPrice = true;
                }
                return model;
            });
            return [{ ...prevState[0], data: newData }];
        });

        let modelIds = [];
        if (models === null) {
            // Update the prices for all models in one single call
            if (typeof modelsData !== "undefined" && modelsData.length > 0) {
                if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                    modelsData[0].data.forEach((model) => {
                        if (model.checked === true) {
                            modelIds.push({
                                id: model.id,
                                settings: getSettingsId(model.settings, productSettings),
                                amount: model.amount,
                            });
                        }
                    });
                }
            }
        } else {
            models.forEach((model) => {
                if (model.checked === true) {
                    modelIds.push({
                        id: model.id,
                        settings: getSettingsId(model.settings, productSettings),
                        amount: model.amount,
                    });
                }
            });
        }

        if (modelIds.length > 0) {
            axios
                .post(window.location.origin + `/api/all-product-prices`, {
                    models: modelIds,
                })
                .then((response) => {
                    if (typeof response.data.data !== "undefined") {
                        const modelLookup = modelsData[0].data.reduce((acc, model) => {
                            acc[model.id] = model;
                            return acc;
                        }, {});

                        response.data.data.forEach((element) => {
                            const model = modelLookup[element.model_id];
                            if (model) {
                                model.price = parseFloat(element.unit_price) > 0 ? element.unit_price : "Op aanvraag";
                                model.discount_multiplier = element.discount_multiplier;
                                setModelsData((prevState) => {
                                    const newData = prevState[0].data.map((stateModel) => {
                                        if (stateModel.id === model.id) {
                                            return {
                                                ...stateModel,
                                                price: model.price,
                                                loadingPrice: false,
                                                discount_multiplier: model.discount_multiplier,
                                            };
                                        }
                                        return stateModel;
                                    });
                                    const result = [{ ...prevState[0], data: newData }];
                                    window.localStorage.setItem("modelsDataQuotation", JSON.stringify(result)); // set local storage
                                    return result;
                                });
                            }
                        });
                    }
                })
                .catch((error) => {
                    setModelsData((prevState) => {
                        const newData = prevState[0].data.map((model) => {
                            if (model.checked === true) {
                                model.loadingPrice = false;
                                model.price = "Op aanvraag";
                            }
                            return model;
                        });
                        return [{ ...prevState[0], data: newData }];
                    });
                })
                .finally(() => {
                    updatingPrices.current = false;
                });
        }
        setUpdatingMultipleModels(false);
    };

    // Update all selected models
    const updatePrices = (selectedOnly = true) => {
        updatingPrices.current = true;
        let selectedModels = [];
        if (selectedOnly) {
            if (typeof modelsData !== "undefined" && modelsData.length > 0) {
                if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                    selectedModels = modelsData[0].data.filter((model) => model.checked === true);
                }
            }
        } else {
            selectedModels = modelsData[0].data;
        }

        if (modelsData[0].data.length === 0) {
            let models = JSON.parse(localStorage.getItem("modelsDataQuotation"));
            if (typeof models === "undefined" || models === null || models.length === 0) {
                updatingPrices.current = false;
                return;
            } else {
                setModelsData((prev) => {
                    return [{ data: models, section: "quotation" }];
                });
            }
        }

        // Get new price
        selectedModels.map((element) => {
            return updatePrice(element);
        });

        // setTimeout(() => {
        //     updatingPrices.current = false;
        // }, 500);
    };

    const saveQuotation = (redirectToCheckout = false) => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0 && typeof modelsData[0].data !== "undefined") {
            // Delete models from database that have been deleted
            originalQuotationModels[0].data.forEach((originalModel) => {
                let found = false;
                modelsData[0].data.forEach((model) => {
                    if (model.id === originalModel.id) {
                        found = true;
                    }
                });
                if (!found) {
                    axios.delete("/models/" + originalModel.id);
                }
            });

            // Check if we need to delete the quotation (if no models are left)
            if (modelsData[0].data.length === 0) {
                axios.delete(window.location.origin + "/quotation/" + quotationId);
                props.navigateToPageName("account_quotations");
                return;
            }

            // Edit the models that have been edited
            const models = modelsData[0].data;
            if (models.length > 0) {
                let modelsPayload = [];
                models.forEach((model) => {
                    // Save the new model preview image
                    axios.post(window.location.origin + "/edit-model", {
                        preview: model.image,
                        id: model.id,
                    });

                    // Bundle models together and sent it to update the quotation
                    let multiplier = 1;
                    if (loggedInUser.company !== null && loggedInUser.company.allow_staffel_discount == true) {
                        multiplier = model.discount_multiplier;
                    }

                    modelsPayload.push({
                        model_id: model.id,
                        settings_id: getSettingsId(model.settings, productSettings),
                        delivery_time: model.delivery + "",
                        price: model.price + "", // Convert to string because the endpoint expects a string
                        discount_multiplier: multiplier + "",
                        discount_price:
                            model.discount_multiplier !== 1
                                ? (model.price * model.amount * multiplier).toFixed(2)
                                : null,
                        amount: model.amount,
                        case:
                            typeof model.settings.case !== "undefined" && model.settings.case !== null
                                ? model.settings.case
                                : null,
                        practitioner:
                            typeof model.settings.practitioner !== "undefined" && model.settings.practitioner !== null
                                ? model.settings.practitioner
                                : null,
                    });
                });

                let data = {
                    quotation_id: quotationId,
                    models: modelsPayload,
                };

                axios.put(window.location.origin + "/api/quotation", JSON.stringify(data));

                // Check if the user is impersonating another user
                let impersonatingDiv = document.getElementById("impersonate");
                if (impersonatingDiv !== null && impersonatingDiv !== undefined) {
                    axios.post(window.location.origin + "/stopimpersonating").then((response) => {
                        window.location.href = window.location.origin + "/admin/aanvragen";
                        const urlParams = new URLSearchParams(window.location.search);
                        const id = urlParams.get("id");
                        window.location.href = "/admin/aanvragen/" + id + "/edit";
                    });
                    return;
                }

                if (redirectToCheckout === true) {
                    localStorage.setItem("modelsData", localStorage.getItem("modelsDataQuotation"));
                    const urlParams = new URLSearchParams(window.location.search);
                    const id = urlParams.get("id");
                    props.navigateToPageName("checkout", "?id=" + id);
                } else {
                    props.navigateToPageName("account_quotations");
                }
            }
        }
    };

    const saveQuotationAndRedirectCheckout = () => {
        saveQuotation(true);
    };

    const updateShippingCosts = async (model, fromTableRow = false) => {
        if (typeof modelsData !== "undefined" && modelsData[0].data.length === 0) return;

        // Show the model loading
        // if (fromTableRow) {
        //     setModelsData((prevState) => {
        //         const newData = prevState[0].data.map((stateModel) => {
        //             if (stateModel.id === model.id) {
        //                 stateModel.delivery = "...";
        //             }
        //             return stateModel;
        //         });

        //         let result = [{ ...prevState[0], data: newData }];
        //         return result;
        //     });
        // }

        if (getSettingsId(model.settings, productSettings) !== "") {
            axios
                .post(window.location.origin + `/api/calculate-shipping-time`, {
                    model_id: model.id,
                    country: "Netherlands",
                    settings_id: getSettingsId(model.settings, productSettings),
                    amount: model.amount,
                })
                .then((response) => {
                    setModelsData((prevState) => {
                        const newData = prevState[0].data.map((stateModel) => {
                            if (
                                stateModel.id === model.id &&
                                response.data !== null &&
                                response.data.delivery_time !== undefined
                            ) {
                                stateModel.delivery = parseInt(response.data.delivery_time);
                            }
                            return stateModel;
                        });

                        let result = [{ ...prevState[0], data: newData }];
                        return result;
                    });
                });
        }
    };

    const getAmountOfSelectedModels = () => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                let selectedModels = modelsData[0].data.filter((model) => model.checked === true);
                return selectedModels.length;
            }
        }
        return 0;
    };

    return (
        <>
            {!isTabletOrMobile && (
                <div className="root">
                    {activeNavigationMenuItem === "navigate-quotation" && !!modelsData && (
                        <>
                            {!props.authenticated && loginModal && (
                                <LoginPopup
                                    textData={!!textData && textData["popups/login"]}
                                    toggleLoginModal={setLoginModal}
                                    navigateToPageName={props.navigateToPageName}
                                />
                            )}
                            <div className="screen-left">
                                {!hasModelsSelected && (
                                    <Upload
                                        textData={!!textData && textData["sections/quotation"]}
                                        afterUpload={afterUpload}
                                        setModelsData={setModelsData}
                                        setUpdatingMultipleModels={setUpdatingMultipleModels}
                                    />
                                )}
                                {hasModelsSelected && (
                                    <ProductSettings
                                        modelsData={modelsData}
                                        updateModelSettings={updateModelSettings}
                                        textData={!!textData && textData}
                                        saveProductSettings={saveProductSettings}
                                        updateSelectedModelsColor={updateSelectedModelsColor}
                                        loggedInUser={loggedInUser}
                                        updateModelColor={updateModelColor}
                                        updateModelDeliveryTime={updateModelDeliveryTime}
                                        updateModelOrientation={updateModelOrientation}
                                        updatePricesOnNextIteration={updatePricesOnNextIteration}
                                        productSettings={productSettings}
                                        noSharing={true}
                                        setUpdatingMultipleModels={setUpdatingMultipleModels}
                                        updatingPrices={updatingPrices.current}
                                        disableProductSettings={disableProductSettings}
                                    />
                                )}
                                <QuotationBlock textData={!!textData && textData["content_blocks/quotation"]} />
                            </div>
                            <div className="screen-right">
                                <div className="header">
                                    <div>
                                        <h1>
                                            {!!textData && textData["sections/quotation"].edit_title_before} #
                                            {getOrderId()}{" "}
                                            {!!textData && textData["sections/quotation"].edit_title_after}
                                        </h1>
                                        {getAmountOfSelectedModels() > 0 && (
                                            <>
                                                <span className="amount-of-models-icon"></span>
                                                <span className="amount-of-models">
                                                    {getAmountOfSelectedModels()}{" "}
                                                    {!!textData && textData["header"].selected}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <NavigationMenu
                                        textData={!!textData && textData}
                                        authenticated={props.authenticated}
                                        toggleLoginModal={setLoginModal}
                                        navigateToPageName={props.navigateToPageName}
                                    />
                                </div>
                                <Table
                                    data={!!modelsData && modelsData}
                                    onModelChange={setModelsData}
                                    textData={!!textData && textData["sections/quotation"]}
                                    textDataLabel={!!textData && textData["sections/label"]}
                                    checkoutState={!!state && state}
                                    popupData={!!textData && textData["popups/save_quotation"]}
                                    loginData={!!textData && textData["popups/login"]}
                                    mandatoryProductOptions={mandatoryProductSettings}
                                    navigateToPageName={props.navigateToPageName}
                                    getPageUrlByName={props.getPageUrlByName}
                                    saveQuotation={saveQuotation}
                                    saveQuotationAndRedirectCheckout={saveQuotationAndRedirectCheckout}
                                    allTextData={!!textData && textData}
                                    updateShippingCosts={updateShippingCosts}
                                    updatePrice={updatePrice}
                                    changedQuantity={setDisableProductSettings}
                                    updatingPrices={updatingPrices.current}
                                />
                            </div>
                        </>
                    )}
                    {activeNavigationMenuItem === "navigate-account" && (
                        <>
                            <div className="screen-left">
                                <Sidebar getPageUrlByName={props.getPageUrlByName} textData={!!textData && textData} />
                            </div>
                            <div className="screen-right">
                                <div className="header">
                                    <div className="header-title">
                                        {(props.currentPage === "account_orders" ||
                                            props.currentPage === "accountsettings") && (
                                            <>
                                                <FontAwesomeIcon icon={faUser} size="xl" />
                                                <h1>{props.title}</h1>
                                            </>
                                        )}
                                    </div>
                                    <NavigationMenu
                                        textData={!!textData && textData}
                                        authenticated={props.authenticated}
                                        navigateToPageName={props.navigateToPageName}
                                    />
                                </div>
                                {props.currentPage === "account_orders" && (
                                    <OrderOverview
                                        navigateToPageName={props.navigateToPageName}
                                        orderData={orders}
                                        textData={!!textData && textData}
                                    />
                                )}
                                {props.currentPage === "account_quotations" && (
                                    <QuotationOverview
                                        navigateToPageName={props.navigateToPageName}
                                        quotationData={savedQuotations}
                                        textData={!!textData && textData}
                                        loggedInUser={loggedInUser}
                                    />
                                )}
                                {props.currentPage === "product_library" && (
                                    <ProductLibraryOverview libraryData={products} textData={!!textData && textData} />
                                )}
                                {props.currentPage === "account_settings" && (
                                    <Settings
                                        navigateToPageName={props.navigateToPageName}
                                        textData={!!textData && textData}
                                    />
                                )}
                                {props.currentPage === "account_details" && (
                                    <Details
                                        navigateToPageName={props.navigateToPageName}
                                        textData={!!textData && textData}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
            {isTabletOrMobile && (
                <div className="root">
                    {activeNavigationMenuItem === "navigate-quotation" && !!modelsData && (
                        <>
                            <div className={responsiveClass + "header"}>
                                <NavigationMenu
                                    textData={!!textData && textData}
                                    authenticated={props.authenticated}
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                />
                            </div>
                            {!hasModelsSelected && (
                                <Upload
                                    type="model"
                                    responsive={responsiveClass}
                                    textData={!!textData && textData["sections/quotation"]}
                                    setModelsData={setModelsData}
                                />
                            )}
                            {hasModelsSelected && (
                                <ProductSettings
                                    modelsData={modelsData}
                                    updateModelSettings={updateModelSettings}
                                    textData={!!textData && textData}
                                    saveProductSettings={saveProductSettings}
                                    loggedInUser={loggedInUser}
                                    updateModelColor={updateModelColor}
                                    productSettings={productSettings}
                                    noSharing={true}
                                    disableProductSettings={disableProductSettings}
                                    updatingPrices={updatingPrices.current}
                                />
                            )}
                            <Table
                                responsive={responsiveClass}
                                data={!!modelsData && modelsData}
                                onModelChange={setModelsData}
                                textData={!!textData && textData["sections/quotation"]}
                                textDataLabel={!!textData && textData["sections/label"]}
                                navigateToPageName={props.navigateToPageName}
                                getPageUrlByName={props.getPageUrlByName}
                                saveQuotation={saveQuotation}
                                saveQuotationAndRedirectCheckout={saveQuotationAndRedirectCheckout}
                                allTextData={!!textData && textData}
                                updateShippingCosts={updateShippingCosts}
                                updatePrice={updatePrice}
                                changedQuantity={setDisableProductSettings}
                            />
                        </>
                    )}
                    {activeNavigationMenuItem === "navigate-account" && !!orders && (
                        <>
                            <div className={responsiveClass + "header"}>
                                <NavigationMenu
                                    textData={!!textData && textData}
                                    authenticated={props.authenticated}
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                />
                            </div>
                            <Sidebar textData={!!textData && textData} responsive={responsiveClass} />
                            {props.currentPage === "account_orders" && (
                                <OrderOverview
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                    orderData={orders}
                                    textData={!!textData && textData}
                                />
                            )}
                            {props.currentPage === "account_quotations" && (
                                <QuotationOverview
                                    responsive={responsiveClass}
                                    quotationData={savedQuotations}
                                    textData={!!textData && textData}
                                    loggedInUser={loggedInUser}
                                />
                            )}
                            {props.currentPage === "product_library" && (
                                <ProductLibraryOverview
                                    responsive={responsiveClass}
                                    libraryData={products}
                                    textData={!!textData && textData}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
            {tempModelViewers.map((modelViewer, index) => {
                return <Fragment key={index}>{modelViewer}</Fragment>;
            })}
        </>
    );
}
